import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
// components
import HorizontalSlider from 'components/molecules/common/HorizontalSlider';
import OutlineButton from 'cds/buttons/Outline';
// slices
import { getSearchRanking } from 'slices/search.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
import { getShuffledArray } from 'lib/utils/array';

// styles
import { palette, mediaQuery } from 'cds/styles';
import { setClickedItems } from 'slices/dataMining';

/**
 * 인기 태그 리스트
 */
const PopularityTagList = () => {
  const router = useRouter();

  const dispatch = useDispatch();
  const tagList = useSelector(state => state.search.keywordRankList);

  const [TagList, setTagList] = useState(null);
  const [isIpadApp, setIsIpadApp] = useState(false);

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  /** 인기 태그 리스트 호출 */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (isIpadApp) {
      return;
    }

    if (tagList.length > 0) {
      return;
    }
    dispatch(getSearchRanking());
  }, [dispatch, isIpadApp, router.isReady, tagList]);

  const onClickItem = useCallback(
    e => {
      dispatch(setClickedItems({ innerText: e.target.textContent, elementType: 'tag' }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!tagList.length) {
      return;
    }

    /**
     * 커스텀 태크 리스트
     * - `암호화폐`, `세력` : 2021.10.27 Goni 요청건.
     */
    const customTagList = ['놀면서 돈벌기'];
    const updateTagList = tagList.concat(customTagList);
    const uniqueTagList = [...new Set(updateTagList)];

    const output = getShuffledArray(uniqueTagList).map((tag, idx) => (
      <li onClick={onClickItem} key={idx}>
        <Link href={`/search?keyword=${tag}`} passHref>
          <TagButton as="a">{tag}</TagButton>
        </Link>
      </li>
    ));
    setTagList(output);
  }, [tagList, onClickItem]);

  return (
    <TagLayout className="noselect">
      <HorizontalSlider>
        <li onClick={onClickItem}>
          <Link href={`/search?keyword=`} passHref>
            <TagButton as="a">전체</TagButton>
          </Link>
        </li>

        {TagList}
      </HorizontalSlider>
    </TagLayout>
  );
};

const TagLayout = styled.div`
  width: 1800px;
  max-width: 100%;
  padding: 20px 32px;
  margin: 0 auto;

  & ul {
    padding: 0;
  }

  & li {
    & ~ * {
      margin-left: 8px;
    }
  }

  ${mediaQuery.xlarge} {
    padding: 20px 72px;
  }
`;

const TagButton = styled(OutlineButton)`
  flex-grow: 0;
  height: 32px;
  padding: 0 12px;
  white-space: nowrap;
  border: 1px solid ${palette.system.grey};
  border-radius: 32px;
  color: ${palette.grey50};
  font-size: 14px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    opacity: 1;
    color: ${palette.grey50};
  }
  &::before {
    z-index: 0;
    border-radius: 32px;
  }

  & > div {
    padding-right: 8px;
    margin-left: 4px;
    z-index: 1;
  }
`;

export default PopularityTagList;
