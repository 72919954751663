import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
// components
import ClassCover from 'components/atoms/photos/ClassCover';
import Avatar from 'components/atoms/photos/Avatar';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery } from 'cds/styles';
import Icon from 'cds/icons';

/**
 * 메인 - 관심클래스 카드
 * @param {object} props
 * @param {import('types/slices/main').RecommendClass} props.data 클래스 정보
 */
const RegisteredItem = ({ data }) => {
  const router = useRouter();
  const hrefInfix =
    data.community.open === 'Y'
      ? `/community/${data.community.id}`
      : data.class_study_status === 'Y'
      ? `/class/lecture/${data.class_id}`
      : `/class/wait/${data.class_id}`;

  const onProfile = e => {
    e.preventDefault();
    router.push(`/user/${data.coach_id}`);
  };

  const onMouseDownAvatar = e => {
    if (e.buttons === 4) {
      e.stopPropagation();
      window.open(`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/new/user/${data.coach_id}`, '_blank');
    }
  };

  const enterCommunity = id => {
    window.location.href = `https://classu.co.kr/community/${id}`;
  };

  const enterClass = id => {
    window.location.href = `https://classu.co.kr/class/lecture/${id}`;
  };

  return (
    <div>
      <Link href={`${process.env.NEXT_PUBLIC_DEFAULT_HOST}${hrefInfix}`} passHref>
        <Item>
          <Thumbnail>
            <ClassCover src={data.photo} alt={data.class_name} />
            <Icon name="ic_play_circle" width={40} height={40} />
          </Thumbnail>
          <Info>
            <div>
              <AvatarButton onClick={onProfile} onMouseDown={onMouseDownAvatar}>
                <Avatar width={32} height={32} src={data.coach_photo} />
              </AvatarButton>
            </div>
            <div>
              <Title>{data.class_name}</Title>
              <Coach>{data.coach_name}</Coach>
              <Status>
                <span>
                  진도율<i>{data.progress_rate}%</i>
                </span>
                <span>
                  주간미션<i>{data.my_mission_per}%</i>
                </span>
              </Status>
              <Progress gauge={`${data.my_mission_per}%`} />
              <Remain>
                <span>
                  {data.class_study_status === 'Y' ? data.end_date_info : data.class_open_date_text}
                </span>
              </Remain>
            </div>
          </Info>
        </Item>
      </Link>
    </div>
  );
};

const Item = styled.a`
  display: block;
  cursor: pointer;
  border-radius: 8px;
`;

const Thumbnail = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  margin-right: 12px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    z-index: 0;

    ${mediaQuery.large} {
      width: 56px;
      height: 56px;
      margin-top: -28px;
      margin-left: -28px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;

  & > div:first-of-type {
    display: none;
    flex: 0 auto;
    width: 32px;
    padding: 4px 0 0;
    margin: 0 12px 0 0;
  }

  & > div:last-of-type {
    overflow: hidden;
    flex: 1 auto;
  }

  ${mediaQuery.medium} {
    & > div:first-of-type {
      display: block;
    }
  }
`;

const AvatarButton = styled.button`
  flex: 0 auto;
  width: 32px;
  height: 32px;
`;

const Title = styled.p`
  position: relative;
  margin: 8px 0 2px;
  ${typography.subtitle4_medium}
  color: ${palette.grey75};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;

  ${mediaQuery.large} {
    margin: 0 0 2px;
    font-size: 15px;
  }
`;

const Coach = styled.span`
  display: block;
  margin-bottom: 6px;
  ${typography.caption2_regular}
  color: ${palette.grey0};

  ${mediaQuery.large} {
    font-size: 14px;
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    display: flex;
    align-items: center;
    flex: 0 auto;
    ${typography.caption2_medium}
    color: ${palette.grey75};
    font-weight: 350 !important;

    & ~ span {
      &::before {
        display: inline-block;
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 100%;
        background-color: currentColor;
        margin: 0 4px;
      }
    }

    i {
      margin-left: 4px;
      ${typography.caption2_medium}
      font-style: initial;
      color: ${palette.red040};
    }
  }
`;

const Progress = styled.div`
  overflow: hidden;
  position: relative;
  width: 95%;
  height: 2px;
  margin-bottom: 12px;
  border-radius: 1px;
  background-color: ${palette.system.BNB};

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: ${({ gauge }) => gauge};
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 1px;
    background-color: ${palette.red040};
  }
`;

const Remain = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    display: flex;
    align-items: center;
    flex: 0 auto;
    height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    background-color: ${palette.BTN.BG};
    ${typography.caption3_medium}
    color: ${palette.grey75};
  }
`;

export default RegisteredItem;
