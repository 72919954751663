import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
// slices
import { getEventBanner } from 'slices/event.thunk';
// hooks
import { useDispatch, useSelector } from 'hooks/common/useStore';
import useSwipe from 'hooks/common/useSwipe';
// styles
import * as typography from 'cds/typography';
import { palette } from 'cds/styles';
// utils
import deviceChecker from 'lib/utils/deviceChecker';

/**
 * 메인 스와이프 배너
 */
const Banner = () => {
  const router = useRouter();

  const dispatch = useDispatch();

  const banner = useSelector(state => state.event.banner);

  const [isApp, setIsApp] = useState(true);
  const { currentIndex, currentList, Slider } = useSwipe({ items: banner });

  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      return setIsApp(true);
    }
    setIsApp(false);
  }, []);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (banner.length) {
      return;
    }

    dispatch(getEventBanner());
  }, [dispatch, banner.length, router.isReady]);

  const Items = currentList.map((v, i) => {
    // TEST
    const isExternal = v.link.indexOf('/webview/external') > -1;

    if (isExternal && isApp) {
      return (
        <Item key={`${v.link}${i}`} url={v.image} bgColor={v.backcolor}>
          <Link href={v.link} passHref>
            <Cover target="_blank" rel="noopener noreferrer" />
          </Link>
        </Item>
      );
    }

    return (
      <Item key={`${v.link}${i}`} url={v.image} bgColor={v.backcolor}>
        <Link href={v.link} passHref>
          <Cover />
        </Link>
      </Item>
    );
  });

  return (
    <Wrapper>
      <Slider>{Items}</Slider>
      <More>
        <strong>{currentIndex}</strong>
        <i>/</i>
        {banner.length}
      </More>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const Item = styled.li`
  overflow: hidden;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  height: 110px;
  font-size: 0;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: height 0.2s ease;
`;

const Cover = styled.a`
  display: flex;
  align-items: center;
  flex: 0 auto;
  width: 100%;
  height: 100%;

  z-index: 0;
`;

const More = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 22px;
  right: 8px;
  bottom: 8px;
  padding: 0 8px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  ${typography.caption3_regular}
  color: ${palette.white};
  line-height: 0;

  & > i {
    margin: 0 2px;
    ${typography.caption3_regular}
    color: inherit;
    line-height: inherit;
  }
`;

export default Banner;
