import { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
// components
import OutlineButton from 'cds/buttons/Outline';
import FillButton from 'cds/buttons/Fill';
import Icon from 'cds/icons';
// hooks
import { useDispatch, useSelector } from 'hooks/common/useStore';
import useSearchModal from 'hooks/common/useSearchModal';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
import { getShuffledArray } from 'lib/utils/array';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery } from 'cds/styles';
import { setClickedItems } from 'slices/dataMining';

/**
 * 커버 이미지를 포함한 카테고리 리스트
 */
const Category = () => {
  const categoryList = useSelector(state => state.category.categoryList);

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [shuffledList, setShuffledList] = useState([]);
  const isShuffled = useRef(false);

  const { onOpenSearch } = useSearchModal();

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  /** 셔플 초기 한 번만 동작하기 */
  useEffect(() => {
    if (isIpadApp) {
      return;
    }

    const already = !categoryList.length || shuffledList.length || isShuffled.current;

    if (already) {
      return;
    }

    setShuffledList(getShuffledArray(categoryList));

    isShuffled.current = true;
  }, [categoryList, isIpadApp, shuffledList, shuffledList.length]);

  const dispatch = useDispatch();

  const onClickItem = e => {
    dispatch(setClickedItems({ innerText: e.target.textContent, elementType: 'tagLink' }));
  };

  const Items = shuffledList.map(v => (
    <Item onClick={onClickItem} key={v.category}>
      <Link href={`/category?categoryId=${v.category}`} passHref>
        <Box as="a">
          <Thumb url={v.photo} />
          <Title>
            <img src={v.icon} alt="" />
            {v.category}
          </Title>
        </Box>
      </Link>
    </Item>
  ));

  return (
    <Wrapper>
      <List>
        <div>{Items.slice(0, 4)}</div>
        <div>
          {Items}
          <AllItem>
            <Link href={`/search?keyword=`} passHref>
              <Box as="a">
                <Title>
                  <Icon name="ic_number_sign_s" width={14} height={14} stroke={palette.white} />
                  전체보기
                </Title>
              </Box>
            </Link>
          </AllItem>
        </div>
      </List>

      <MoreLink size="44px" color={palette.grey050} onClick={onOpenSearch}>
        카테고리 모두 보기
      </MoreLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const List = styled.ul`
  position: relative;
  padding: 0 32px;
  margin-left: -16px;
  font-size: 0;
  white-space: nowrap;

  & > div:first-of-type {
    display: block;
  }

  & > div:last-of-type {
    display: none;
  }

  ${mediaQuery.large} {
    padding: 0 0;
    margin-left: -20px;
    white-space: normal;

    & > div:first-of-type {
      display: none;
    }

    & > div:last-of-type {
      display: block;
    }
  }
`;

const Item = styled.li`
  overflow: hidden;
  display: inline-block;
  width: calc(100% / 4);
  padding-left: 16px;
  transition: width 0.2s ease, padding 0.2s ease, margin 0.2s ease;
  vertical-align: top;
  ${mediaQuery.small} {
    width: calc(100% / 5);
  }
  ${mediaQuery.medium} {
    width: calc(100% / 6);
  }
  ${mediaQuery.large} {
    padding-left: 20px;
    margin-bottom: 20px;
  }
`;

const AllItem = styled(Item)`
  display: none;
  ${mediaQuery.large} {
    display: inline-block;
  }
`;

const Box = styled(FillButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mediaQuery.large} {
    height: 64px;
    border-radius: 4px;
    background-color: ${palette.BTN.BG};
  }
`;

const Thumb = styled.div`
  overflow: hidden;
  flex: 0 auto;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 4px;
  margin: 0 auto 6px;
  background-color: transparent;
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaQuery.large} {
    display: none;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  ${typography.caption2_medium}
  color: ${palette.grey75};
  text-align: center;
  word-break: keep-all;
  svg {
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
  img {
    display: none;
    width: 18px;
    max-width: 100%;
    margin-right: 6px;
    filter: brightness(0) invert(1) grayscale(100%);
  }
  ${mediaQuery.large} {
    ${typography.body2_regular}
    & > img {
      display: block;
    }
  }
`;

const MoreLink = styled(OutlineButton)`
  display: block;
  width: calc(100% - 32px);
  padding: 0 16px;
  margin: 16px 16px 0;
  ${typography.body2_regular}
  color: ${palette.grey50};
  ${mediaQuery.large} {
    display: none;
  }
  background-color: ${palette.BTN.BG};
  border: none;
`;

export default Category;
